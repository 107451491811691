<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

        <div v-if="is_API_loaded" class="space-y-10">
          <!-- 點數功能開關 -->
          <section class="wrap">
            <div class="leftBlock">
              <!-- 摘要 -->
              <div class="p-4 rounded-xl bg-white space-y-4">
                <h3 class="h3">發送條件摘要</h3>
                <div class="space-y-2">
                  <p class="list_item">
                    單筆消費(不含運費)每滿 NT${{ setting.point2_pays | currency }}，發送 {{ setting.point2_point }}點 點數，以此類推。
                  </p>
                  <p class="list_item">點數將會在訂單 已取貨/已送達 的 {{ setting.point2_send }} 天後發送給會員。</p>
                </div>
              </div>
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">點數功能開關</h3>
                <vs-switch warn v-model="setting.point2_switch" />
              </div>

              <div class="p-4">
                <h3 class="h3 text-center mb-4">點數發送條件設定</h3>
                <div class="flex flex-col p-4 space-y-6 bg-gray-background rounded-lg">
                  <div>
                    <h4 class="h4 mb-1">單筆消費(不含運費)每滿</h4>
                    <div class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="單筆消費"
                        :rules="`${setting.point2_switch ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point2_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">發送多少點數</h4>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      name="發送點數"
                      :rules="`${setting.point2_switch ? 'required' : ''}|numeric|min_value:1`"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model="setting.point2_point"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">已取貨/已送達 後多少天發送點數</h4>
                    <div class="flex items-center space-x-2">
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="天數"
                        :rules="`${setting.point2_switch ? 'required' : ''}|numeric|min_value:1|max_value:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point2_send"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                      <span>天</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- 點數轉購物金 -->
          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">點數轉換購物金</h3>
              <p>設定後會員可自行將紅利點數轉換為購物金。</p>
            </div>

            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">點數轉換購物金</h3>
                <!-- <vs-switch warn v-model="setting.point2_switch" /> -->
              </div>

              <div class="rightBlock_content">
                <div>
                  <h4 class="h4 mb-1">每 多少點數 可以兌換成 NT$1 購物金</h4>
                  <ValidationProvider
                    tag="div"
                    class="w-full relative"
                    name="點數"
                    :rules="`${setting.point2_switch ? 'required' : ''}|numeric|min_value:1`"
                    v-slot="{ errors }"
                  >
                    <vs-input v-model="setting.point2_point1"></vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <p class="text-gray-400">
                  例如：小明帳戶有5000點紅利點數，假設每300點可以兌換成 NT$1 元，小明最高可以兌換成 NT$16 元的購物金。
                </p>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-between">
            <router-link :to="{ name: 'rewardsPoints-history' }">
              <vs-button size="large" border>查閱發送紀錄</vs-button>
            </router-link>
            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="save(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="rightBlock_content">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'rewardsPoints',
  data() {
    return {
      is_API_loaded: false,
      setting: {},
    }
  },
  created() {
    this.loadSetting()
  },
  watch: {
    // 若總開關關閉，全部關閉
    'setting.point1_discount'(val) {
      if (!val) {
        this.setting.point1_member = false
        this.setting.point1_birthday = false
        this.setting.point1_shopfull = false
      }
    },
  },
  methods: {
    // 3.2.4.1 點數參數設定-讀取
    loadSetting() {
      this.$axios({
        url: 'front/store/point2/getPoint2ParamUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          function booleanChange(value) {
            return value ? true : false
          }

          let data = res.data.Data
          data.point2_switch = booleanChange(data.point2_switch)
          this.setting = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.2.4.2 點數參數設定-修改
    save(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/point2/uPoint2ParamData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          point2_switch: this.setting.point2_switch ? 1 : 0,
          point2_pays: this.setting.point2_pays,
          point2_point: this.setting.point2_point,
          point2_send: this.setting.point2_send,
          point2_point1: this.setting.point2_point1,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.loadSetting()
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white rounded-xl;
}

.rightBlock_title {
  @apply flex items-center justify-between border-b p-4;
}

.rightBlock_content {
  @apply p-4 space-y-6;
}

.list_item {
  @apply ml-0 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}
</style>
