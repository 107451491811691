var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('h1',{staticClass:"page_title mb-5"},[_vm._v(_vm._s(_vm.$route.meta.title))]),(_vm.is_API_loaded)?_c('div',{staticClass:"space-y-10"},[_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('div',{staticClass:"p-4 rounded-xl bg-white space-y-4"},[_c('h3',{staticClass:"h3"},[_vm._v("發送條件摘要")]),_c('div',{staticClass:"space-y-2"},[_c('p',{staticClass:"list_item"},[_vm._v(" 單筆消費(不含運費)每滿 NT$"+_vm._s(_vm._f("currency")(_vm.setting.point2_pays))+"，發送 "+_vm._s(_vm.setting.point2_point)+"點 點數，以此類推。 ")]),_c('p',{staticClass:"list_item"},[_vm._v("點數將會在訂單 已取貨/已送達 的 "+_vm._s(_vm.setting.point2_send)+" 天後發送給會員。")])])])]),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"rightBlock_title"},[_c('h3',{staticClass:"h3"},[_vm._v("點數功能開關")]),_c('vs-switch',{attrs:{"warn":""},model:{value:(_vm.setting.point2_switch),callback:function ($$v) {_vm.$set(_vm.setting, "point2_switch", $$v)},expression:"setting.point2_switch"}})],1),_c('div',{staticClass:"p-4"},[_c('h3',{staticClass:"h3 text-center mb-4"},[_vm._v("點數發送條件設定")]),_c('div',{staticClass:"flex flex-col p-4 space-y-6 bg-gray-background rounded-lg"},[_c('div',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("單筆消費(不含運費)每滿")]),_c('div',{staticClass:"flex items-center space-x-2"},[_c('span',[_vm._v("NT$")]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"單筆消費","rules":((_vm.setting.point2_switch ? 'required' : '') + "|numeric|min_value:1")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.point2_pays),callback:function ($$v) {_vm.$set(_vm.setting, "point2_pays", $$v)},expression:"setting.point2_pays"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("發送多少點數")]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"發送點數","rules":((_vm.setting.point2_switch ? 'required' : '') + "|numeric|min_value:1")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.point2_point),callback:function ($$v) {_vm.$set(_vm.setting, "point2_point", $$v)},expression:"setting.point2_point"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("已取貨/已送達 後多少天發送點數")]),_c('div',{staticClass:"flex items-center space-x-2"},[_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"天數","rules":((_vm.setting.point2_switch ? 'required' : '') + "|numeric|min_value:1|max_value:30")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.point2_send),callback:function ($$v) {_vm.$set(_vm.setting, "point2_send", $$v)},expression:"setting.point2_send"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',[_vm._v("天")])],1)])])])])]),_c('section',{staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('h3',{staticClass:"h3 sm:mt-4"},[_vm._v("點數轉換購物金")]),_c('p',[_vm._v("設定後會員可自行將紅利點數轉換為購物金。")])]),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"rightBlock_title"},[_c('h3',{staticClass:"h3"},[_vm._v("點數轉換購物金")])]),_c('div',{staticClass:"rightBlock_content"},[_c('div',[_c('h4',{staticClass:"h4 mb-1"},[_vm._v("每 多少點數 可以兌換成 NT$1 購物金")]),_c('ValidationProvider',{staticClass:"w-full relative",attrs:{"tag":"div","name":"點數","rules":((_vm.setting.point2_switch ? 'required' : '') + "|numeric|min_value:1")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.setting.point2_point1),callback:function ($$v) {_vm.$set(_vm.setting, "point2_point1", $$v)},expression:"setting.point2_point1"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"text-gray-400"},[_vm._v(" 例如：小明帳戶有5000點紅利點數，假設每300點可以兌換成 NT$1 元，小明最高可以兌換成 NT$16 元的購物金。 ")])])])]),_c('hr'),_c('div',{staticClass:"flex items-center justify-between"},[_c('router-link',{attrs:{"to":{ name: 'rewardsPoints-history' }}},[_c('vs-button',{attrs:{"size":"large","border":""}},[_vm._v("查閱發送紀錄")])],1),_c('vs-button',{ref:"saveBtn",attrs:{"disabled":invalid,"size":"large"},on:{"click":function($event){return _vm.save(invalid)}}},[_vm._v("儲存")])],1)]):_c('div',{staticClass:"space-y-4"},_vm._l((2),function(item,index){return _c('section',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"leftBlock"},[_c('skeleton',{staticClass:"mt-4",attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}}),_c('skeleton',{attrs:{"width":"200px","height":"10px"}})],1),_c('div',{staticClass:"rightBlock"},[_c('div',{staticClass:"rightBlock_title"},[_c('skeleton',{attrs:{"width":"100px"}}),_c('skeleton',{attrs:{"width":"100px"}})],1),_c('div',{staticClass:"rightBlock_content"},_vm._l((3),function(item,index){return _c('skeleton',{key:index,attrs:{"width":"200px","height":"10px"}})}),1)])])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }